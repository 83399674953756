<template>
  <Layout>
    <page-layout page-titles="SaaS管理">
      <template #table-content>
        <management-service-table :has-add-button="false" :show-edit-dialog="showEditDialog">
          <template #dialog>
            <c-modal :id="dialogId" :title="dialogTitle" :buttons="dialogButtons">
              <management-service-dialog
                :form-data.sync="formData"
                :trigger-validate="triggerValidate"
                :is-valid.sync="isValid"
              />
            </c-modal>
          </template>
        </management-service-table>
      </template>
    </page-layout>
  </Layout>
</template>

<script>
import Layout from '../../layouts/main/app'
import ManagementServiceDialog from '../../components/forms/management-service-form'
import PageLayout from '@/components/commons/common-page-layout'
import ManagementServiceTable from '../../components/tables/management-service-table'
import CModal from '../../components/commons/common-modal'
import { saasManagementService } from '@/services/saas-management-service'
import { mapActions } from 'vuex'
import { ALERT_TYPE } from '@/utils/constants'
import { MESSAGES } from '@/utils/messages'
import pick from 'lodash-es/pick'
import { isHttpLink } from '@/utils/object-helpers'
export default {
  name: 'ManagementService',
  components: {
    ManagementServiceTable,
    ManagementServiceDialog,
    Layout,
    PageLayout,
    CModal,
  },
  data() {
    return {
      dialogId: 'ms-dialog',
      dialogTitle: '',
      dialogTitles: {
        ADD: {
          id: 1,
          text: 'サービス新規登録',
        },
        EDIT: {
          id: 2,
          text: 'サービス情報編集',
        },
      },

      formData: null,
      triggerValidate: false,
      isValid: false,
      onSave: () => {
        return {}
      },
      requiredEditKeys: ['name', 'category_id', 'status', 'logo'],
      editId: ['id'],
    }
  },
  computed: {
    dialogButtons() {
      return [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: '更新',
          variant: 'primary',
          minWidth: 133,
          size: 'md',
          func: this.onSave,
        },
      ]
    },
  },
  methods: {
    ...mapActions('alert', ['displayAlert']),
    ...mapActions('commonSettings', ['fetchCommonSetting']),
    showEditDialog(rowData, editSuccessCallback) {
      this.dialogTitle = this.dialogTitles.EDIT.text
      this.formData = { ...rowData }
      this.onSave = async () => {
        this.triggerValidate = !this.triggerValidate
        setTimeout(async () => {
          if (this.isValid) {
            this.callbackFn = editSuccessCallback
            await this.onUpdateConfirm()
          }
        }, 150)
      }
      this.includeFields = []
      this.$bvModal.show(this.dialogId)
    },
    async onUpdateConfirm() {
      const formData = pick(this.formData, this.requiredEditKeys)
      if (isHttpLink(formData.logo)) {
        delete formData.logo
      }
      const id = pick(this.formData, this.editId)
      const { success, data } = await saasManagementService.editItem(id, formData)
      if (!success) {
        this.displayAlert({
          type: ALERT_TYPE.ERROR,
          messages: data.name ? MESSAGES.ADMIN_MT.ADM01 : MESSAGES.COMMON.MSG15,
        })
        return
      }
      this.callbackFn && this.callbackFn()
      this.$bvModal.hide(this.dialogId)
      await this.fetchCommonSetting()
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.COMMON.MSG02,
      })
    },
  },
}
</script>
