<template>
  <div class="Table MSTable">
    <div class="Table__toolbar">
      <div class="Table__toolbar-search">
        <search-input v-if="hasSearch" :value.sync="searchInput" />
        <slot name="left" />
      </div>
      <div class="Table__toolbar-buttons">
        <slot name="right" />
        <add-new-button v-if="hasAddButton" class-name="add-button" :func="onCreate" />
      </div>
    </div>

    <div class="table-responsive">
      <b-table
        ref="ms-table"
        :items="items"
        :fields="fields"
        sort-by="code"
        thead-class="Table__header MSTable__header"
        tbody-tr-class="Table__row MSTable__row"
        hover
      >
        <template #head()="data">
          <div v-if="!!data.label" class="header">
            <div class="header--value">
              <span>{{ data.label }}</span>
            </div>
            <div v-if="data.field.sortable" class="header--sort">
              <SortIcon />
            </div>
          </div>
          <div v-else class="action">
            {{ '' }}
          </div>
        </template>
        <template #cell(logo)="data">
          <img :src="data.value" alt="image" />
        </template>

        <template #cell(name)="data">
          <router-link
            :to="{
              name: 'detail-service',
              query: {
                id: data.item.id,
              },
            }"
          >
            {{ data.value }}
          </router-link>
        </template>
        <template #cell(status)="data">
          <div>
            <div :class="checkClass(data.value)">
              {{ getStatus(data.value) }}
            </div>
          </div>
        </template>
        <template #cell(actions)="data">
          <div class="cell">
            <img
              src="@/assets/icons/ic_edit.svg"
              alt="edit-icon"
              @click.stop.prevent="onEdit(data.item)"
            />
          </div>
        </template>
      </b-table>
    </div>
    <div class="Table__paging">
      <table-paging
        :current-page.sync="currentPage"
        :per-page.sync="perPage"
        :total="total"
        :options-paging="optionsPaging"
      />
    </div>
    <slot name="dialog" />
  </div>
</template>

<script>
import SearchInput from '@/components/commons/common-search-input'
import AddNewButton from '@/components/add-new-button'
import SortIcon from '@/components/sort-icon'
import TablePaging from '@/components/commons/common-table-paging'
import { saasManagementService } from '@/services/saas-management-service'
import { mapGetters } from 'vuex'
export default {
  name: 'ManagementServiceTable',
  components: {
    SearchInput,
    AddNewButton,
    SortIcon,
    TablePaging,
  },
  props: {
    hasSearch: {
      type: Boolean,
      default: true,
    },
    hasAddButton: {
      type: Boolean,
      default: true,
    },
    showEditDialog: {
      type: Function,
      default: () => {
        return {}
      },
    },
    showAddDialog: {
      type: Function,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      items: [],
      fields: saasManagementService.getFields(),
      searchInput: '',
      currentPage: 1,
      perPage: 10,
      optionsPaging: [10, 50, 100],
      total: 0,
    }
  },
  computed: {
    ...mapGetters('commonSettings', ['mt_status']),
  },
  watch: {
    searchInput(newValue) {
      this.search(newValue)
    },
    perPage() {
      this.search(this.searchInput)
    },
    currentPage() {
      this.search(this.searchInput)
    },
  },
  async created() {
    await this.loadTable()
  },
  methods: {
    checkClass(status) {
      return status === this.mt_status[1].id
        ? 'cell__status cell__status-active'
        : 'cell__status cell__status-inactive'
    },
    getStatus(status) {
      const statusLabel = Object.values(this.mt_status).filter((item) => item.id === status)[0]
      return statusLabel ? statusLabel.name : ''
    },
    onEdit(rowData) {
      this.showEditDialog(rowData, () => this.loadTable(true))
    },
    async loadTable(isMaster = false) {
      this.searchInput = ''
      const { items, total } = await saasManagementService.getDataTable(
        this.perPage,
        this.currentPage,
        this.searchInput,
        isMaster
      )
      this.items = items
      this.total = total
    },
    async search(keySearch) {
      const { items, total } = await saasManagementService.getDataTable(
        this.perPage,
        this.currentPage,
        keySearch
      )
      this.items = items
      this.total = total
    },
    onCreate() {
      this.showAddDialog(() => this.loadTable(true))
    },
  },
}
</script>
